import React from "react"
import StyledChallenges from "./style/challenges";
import { FaArrowRight } from "react-icons/fa";

const Challenges = ({ data }) => {
  return (
    <StyledChallenges>
      <div className="image-section">
        <div className="image-holder"
          style={{ backgroundImage: `url(${data.image.publicURL})` }}
        >
        </div>
      </div>
      <div className="right">
        <div className="main">
          <h2>{data.heading}</h2>
          <ul>
            {data.list.map((d, i) => (
              <li key={i}>
                <div className="wrapper">
                  <FaArrowRight className="icon" />
                  <span>{d.heading}</span>
                </div>
                <p>
                  {d.description}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </StyledChallenges>
  )
};

export default Challenges
