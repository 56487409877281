import tw, { styled } from "twin.macro"
const StyledOverview = styled.section`
  ${tw`md:py-14 py-6`};
  .main-container {
    ${tw`md:(px-5) px-3 max-w-8xl mx-auto z-[1] relative`}

    .wrapper {
      ${tw`w-full flex md:flex-row flex-col gap-8`}

      .left {
        ${tw`md:w-2/5 w-full flex flex-col gap-4`}

        h2 {
          ${tw`md:text-4xl text-2xl font-bold text-white`}
        }

        h6 {
          ${tw`text-white/70 font-medium text-base`}
        }
      }

      .right {
        ${tw`md:w-3/5 w-full`}

        p {
          ${tw`text-white/70 font-medium leading-7`}
        }
      }
    }

    .details {
      ${tw`pt-8`}

      ul {
        ${tw`grid md:grid-cols-4 grid-cols-1`}

        li {
          ${tw`list-none`}
          div {
            &:first-child {
              ${tw`text-primary font-bold`}
            }
            &:nth-child(2) {
              ${tw`text-white/70 font-medium`}
            }
          }

          a {
            ${tw`text-secondary font-medium`}
          }
        }
      }
    }
  }
`
export default StyledOverview
