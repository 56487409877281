import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Banner from "../../components/case-studies-details/Banner"
import Overview from "../../components/case-studies-details/Overview"
import Challenges from "../../components/case-studies-details/Challenges"
import Solutions from "../../components/case-studies-details/Solutions"
import Results from "../../components/case-studies-details/Results"
import Conclusion from "../../components/case-studies-details/Conclusion"
import MetaTags from "react-meta-tags"
import SEO from "../../components/seo"

const CaseStudiesDetails = ({ data }) => {
  const details = data.markdownRemark.frontmatter
  return (
    <Layout className="page">
      <SEO
        title="Case Studies | Cresol Infoserv"
        description="Discover how Cresol Infoserv has helped businesses achieve success through innovative digital solutions. Explore our case studies and see the results."
        keywords="best website development company, website design and development services, best digital marketing services, software testing services, mobile app development company"
      />
      {/* <Banner image={details.bannerImage} title={details.title} /> */}
      <Overview data={details.overview} />
      <Challenges data={details.challenges} />
      <Solutions data={details.solution} />
      <Results data={details.results} />
      <Conclusion data={details.conclusion} />
    </Layout>
  )
}

export default CaseStudiesDetails

export const pageQuery = graphql`
  query BlogPostQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        bannerImage {
          publicURL
        }
        overview {
          heading
          subHeading
          description
          client
          categories
          location
          projectUrl
        }
        challenges {
          image {
            publicURL
          }
          heading
          list {
            heading
            description
          }
        }
        solution {
          image {
            publicURL
          }
          heading
          subHeading
          list {
            heading
            description
          }
        }
        results {
          image {
            publicURL
          }
          heading
          subHeading
          list {
            heading
            description
          }
        }
        conclusion {
          image {
            publicURL
          }
          heading
          description
        }
      }
    }
  }
`
