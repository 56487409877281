import React from "react"
import StyledOverview from "./style/overview"

const Overview = ({ data }) => {
  return (
    <StyledOverview>
      <div className="main-container">
        <div className="wrapper">
          <div className="left">
            <h2>{data.heading}</h2>
            <h6>{data.subHeading}</h6>
          </div>
          <div className="right">
            <p>{data.description}</p>
          </div>
        </div>
        <div className="details">
          <ul>
            {/* <li>
              <h3>Client</h3>
              <div>{data.client}</div>
            </li>
            <li>
              <h3>Categories</h3>
              <div>{data.categories}</div>
            </li> */}
            <li>
              <h3>Location</h3>
              <div>{data.location}</div>
            </li>
            <li>
              <h3>Project Url</h3>
              <a href={data.projectUrl} target="_blank" rel="noreferrer">
                {data.projectUrl}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </StyledOverview>
  )
}

export default Overview
