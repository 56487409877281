import React from "react"
import StyledConclusion from "./style/conclusion";

const Conclusion = ({ data }) => {
  return (
    <StyledConclusion>
      <div className="image-section">
        <div className="image-holder"
          style={{ backgroundImage: `url(${data.image.publicURL})` }}
        >
        </div>
      </div>
      <div className="right">
        <div className="main">
          <h2>{data.heading}</h2>
          <p>
            {data.description}
          </p>
        </div>
      </div>
    </StyledConclusion>
  )
};

export default Conclusion
