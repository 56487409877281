import tw, { styled } from "twin.macro"
const StyledChallenges = styled.section`
  ${tw`md:pb-12 pb-6 relative flex md:flex-row flex-col justify-end`}
  .image-section {
    ${tw`md:(absolute h-full) h-[400px] relative top-0 p-0 left-0 right-auto md:w-1/2 w-full`}
    .image-holder {
      ${tw`absolute h-full top-0 left-0 bg-cover [background-position: 50% 50%] w-full`}
    }
  }
  .right {
    ${tw`flex flex-col md:(w-[92rem] py-5) py-2 w-full md:(px-5) px-3 mx-auto relative`}
    .main {
      ${tw`md:(pl-8 ml-auto w-1/2) pl-0 w-full`}
      h2 {
        ${tw`md:text-4xl text-2xl font-bold text-white`}
      }
      ul {
        ${tw`flex flex-col gap-6 pt-4`}

        li {
          ${tw`flex flex-col gap-2`}
          .wrapper {
            ${tw`flex flex-row gap-x-4 justify-items-start items-start text-white font-medium`}
            .icon {
              ${tw`flex-shrink-0 mt-0.5`}
            }
          }
          p {
            ${tw`text-white/70 font-medium`}
          }
        }
      }
    }
  }
`
export default StyledChallenges
